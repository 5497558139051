import React from "react";
import styled from "styled-components";
import { LayoutDefault } from "../components/LayoutDefault";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import {
  CenteredContentWrapper,
  EmphasizedText,
  Hero,
  HeroNew,
  SectionHeader,
  Subheading,
  TextBody,
  TextToutFullwidth,
  ThumbnailHeader,
  ToutGroupNew,
  TwoColumnContentNodeWrapper,
  TwoColumnsWithImage,
  ThreeUpCollage,
  device,
} from "@xolvio/xolvio-ui";
import {
  HeroContent,
  HeroContentWrapper,
  InlineHeroWithTwoColumnsHeader,
  TwoColumnSectionHeader,
} from "../components/Services/ServicePageTemplate";
import { StyledImg } from "./services/rapid-backend-development";
import {
  getBenefitItem,
  heroProps,
  pageData,
} from "../pages-content/deliberate-scaling";
import { ContentWrapper } from "quality-faster/src/components/LandingPage/ContentWrapper";
import { MoveHack } from "../components/Services";
import { ToutRow } from "../components/Homepage";

export const AlternateBackground = styled.div`
  background-color: rgb(237, 239, 238);
  padding: 20px 0;
`;

const AlignToLeftOnMobile = styled(TextBody)`
  max-width: 100%;
  text-align: center;

  @media ${device.mobile} {
    text-align: left;
  }
`;

export const BenefitsWrapper = styled.div`
  h1 {
    max-width: 1000px !important;

    @media ${device.mobile} {
      text-align: center;
    }
  }
`;

const BenefitsItemsWrapper = styled.div`
  h1 {
    font-size: 30px;
    font-weight: normal;
    text-align: left;
  }
`;

const HeroWrapper = styled.div`
  @media ${device.wide} {
    h1,
    p {
      max-width: 80vw;
    }
  }
  @media ${device.phone} {
    h1 {
      box-sizing: border-box;
      padding: 0 70px;
    }
  }
`;

export default () => (
  <LayoutDefault>
    <StaticQuery
      query={graphql`
        query {
          hero: file(relativePath: { eq: "mountains_hero.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1700) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          collage1: file(
            relativePath: { eq: "office_people_working_on_laptops.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          collage2: file(
            relativePath: { eq: "people_talking_brainstorming.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1700) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          collage3: file(
            relativePath: { eq: "flow-diagram-software-development.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1700) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          whiteTrian: file(relativePath: { eq: "white-trian.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1700) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <>
            <HeroWrapper>
              <Hero
                {...heroProps}
                elementWithBackgroundImage={
                  <BackgroundImage
                    fluid={data.hero.childImageSharp.fluid}
                    style={{ height: 790, backgroundPosition: "right" }}
                  />
                }
              />
            </HeroWrapper>

            <MoveHack topMobile={"-200px"} />

            <HeroNew
              inline
              height={"493px"}
              heading={
                <InlineHeroWithTwoColumnsHeader>
                  <EmphasizedText
                    text="You’re faced with increasing complexity from numerous and aging enterprise systems."
                    highlight="complexity"
                  />
                </InlineHeroWithTwoColumnsHeader>
              }
              children={
                <HeroContentWrapper>
                  <HeroContent>
                    <TextBody bold>
                      {
                        "Your systems’ current state cripples you from delivering better customer experiences. Your team is slowed down by endless integration bugs and a codebase that’s difficult to change."
                      }
                    </TextBody>
                    <TextBody>
                      {
                        "You’re under pressure from ever-increasing demand for transformation in a landscape where everything is distributed. Meanwhile, you’re working with monoliths. But what if there’s a way to scale your software delivery without entirely replatforming your systems?"
                      }
                    </TextBody>
                  </HeroContent>
                </HeroContentWrapper>
              }
            />

            <ThreeUpCollage
              subheading="WHAT WE DO"
              title="Introducing Deliberate Scaling"
              highlightedTitleFragments={["Deliberate"]}
              contentBlock="Through many years of digital transformation and development work at Xolvio, we created a unique method to solve the enterprise IT delivery conundrum. We know exactly how to build a distributed, flexible architecture on top of your existing systems, so that you’re freed from previous constraints without breaking a thing. Once this is done, we can implement our systematic approach to software delivery, allowing you to produce high-quality code at unprecedented speed."
              imgLeft={
                <BackgroundImage
                  fluid={data.collage1.childImageSharp.fluid}
                  durationFadeIn={200}
                />
              }
              imgMain={
                <BackgroundImage
                  fluid={data.collage3.childImageSharp.fluid}
                  durationFadeIn={200}
                />
              }
              imgBottom={
                <BackgroundImage
                  fluid={data.collage2.childImageSharp.fluid}
                  durationFadeIn={200}
                />
              }
            />

            <MoveHack topMobile={"60px"} />

            <TwoColumnsWithImage
              imagePosition={"right"}
              imageNode={
                <StyledImg
                  src="/assets/images/systems_modernization.svg"
                  width="500px"
                />
              }
              contentNode={
                <TwoColumnContentNodeWrapper>
                  <Subheading>{"DELIBERATE SCALING: STAGE ONE"}</Subheading>
                  <TwoColumnSectionHeader>
                    <EmphasizedText
                      highlight="Flexible"
                      text="Flexible & distributed architecture layer"
                    />
                  </TwoColumnSectionHeader>
                  <TextBody>
                    {
                      "In order to remove roadblocks from your software delivery, we need to first decouple your monolith. We do this safely by attaching special modules to your existing system to enable an event-driven architecture. This will serve as the foundation for scaling the system, while allowing for a deliberate, step-wise refactor in parallel."
                    }
                  </TextBody>
                </TwoColumnContentNodeWrapper>
              }
            />

            <MoveHack top={"-80px"} topMobile={"60px"} />

            <TwoColumnsWithImage
              imagePosition={"left"}
              imageNode={
                <StyledImg
                  src="/assets/images/end-to-end-delivery.svg"
                  width="500px"
                />
              }
              contentNode={
                <TwoColumnContentNodeWrapper>
                  <Subheading>{"DELIBERATE SCALING: STAGE TWO"}</Subheading>
                  <TwoColumnSectionHeader>
                    <EmphasizedText
                      highlight="frictionless"
                      text="A frictionless delivery method"
                    />
                  </TwoColumnSectionHeader>
                  <TextBody>
                    {
                      "Once we have removed your architecture constraints, we can implement our holistic and systematic delivery method that will allow you to smoothly go from business needs, through business requirements, to high-quality code delivered at speed. This is possible thanks to our unique blend of Domain-Driven Design, Behavior-Driven Development, and test automation, combined into a step-by-step, bulletproof process."
                    }
                  </TextBody>
                </TwoColumnContentNodeWrapper>
              }
            />

            <MoveHack topMobile={"60px"} />

            <AlternateBackground>
              <CenteredContentWrapper id="howWeDoIt">
                <ContentWrapper>
                  <Subheading style={{ textAlign: "center" }}>
                    {pageData.howWeDoIt.subheading}
                  </Subheading>
                  <SectionHeader>{pageData.howWeDoIt.title}</SectionHeader>
                  <AlignToLeftOnMobile>
                    {pageData.howWeDoIt.body}
                  </AlignToLeftOnMobile>

                  <MoveHack top={"100px"} topMobile={"75px"} />

                  {pageData.howWeDoIt.sections.map((row) => (
                    <ToutRow>
                      {row.map((section) => (
                        <div>
                          <ThumbnailHeader style={{ margin: "auto" }}>
                            {section.title}
                          </ThumbnailHeader>
                          <TextBody
                            style={{ margin: "auto", textAlign: "left" }}
                          >
                            {section.body}
                          </TextBody>
                        </div>
                      ))}
                    </ToutRow>
                  ))}
                </ContentWrapper>
              </CenteredContentWrapper>
            </AlternateBackground>

            <MoveHack top={"150px"} />

            <BenefitsWrapper>
              <HeroNew
                inline
                id="benefits"
                actionButtons={null}
                heading={pageData.whatYouGet.title}
                subheading={pageData.whatYouGet.subheading}
                elementWithBackgroundImage={null}
              >
                <BenefitsItemsWrapper>
                  <ToutGroupNew
                    iconTout1={getBenefitItem(0)}
                    iconTout2={getBenefitItem(1)}
                  />
                  <ToutGroupNew
                    iconTout1={getBenefitItem(2)}
                    iconTout2={getBenefitItem(3)}
                  />
                  <ToutGroupNew
                    iconTout1={getBenefitItem(4)}
                    iconTout2={getBenefitItem(5)}
                  />
                </BenefitsItemsWrapper>
              </HeroNew>
            </BenefitsWrapper>

            <TextToutFullwidth
              subheading="GET IN TOUCH"
              heading="Let’s talk how you can scale deliberately"
              content="Send us a message or book a call today to start transforming your software delivery for tomorrow."
              buttonText="Contact us"
              buttonUrl="/contact-us"
            />
          </>
        );
      }}
    />
  </LayoutDefault>
);
