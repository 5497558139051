import React from 'react'
import styled from 'styled-components'
import { StarIcon, device } from '@xolvio/xolvio-ui'

const IconWrapper = styled.div`
  transform: translateY(12px);

  @media ${device.mobile} {
    transform: translateY(20px);
  }
`

export const heroProps = {
  title: "Deliberate Scaling",
  highlightedTitleFragments: "Scaling",
  height: "790px",
  contentBlock: <>Transform your software delivery for the age of distributed systems.</>,
  actionButtons: null,
};

export const pageData = {
  howWeDoIt: {
    subheading: "HOW WE DO IT",
    title: "The Deliberate Scaling Palette",
    body: "The Deliberate Scaling approach was born at Xolvio thanks to years of experience and experimentation with novel techniques, practices, and technologies. This allowed us not only to bring the best out of these various ingredients but also synthesize them into a holistic, practical methodology. Get to know the Deliberate Scaling palette:",
    sections: [
      [
        { title: "Big Picture Event Storm", body: "Putting business in the driving seat, we use event storming to create a shared understanding that meets your goals. This highly effective workshop technique will get your team on the same page in just a matter of hours without copious documentation that no one ever reads." },
        { title: "Process Modeling", body: "Having a clear picture of your business domains, we dig deeper to specify the business processes and their associated rules. For this purpose, we incorporate BDD into event storming for a potent combination that lets us rapidly arrive at a solid foundation for domain digitization." },
        { title: "Domain Digitization", body: "Armed with all the knowledge about your business, we codify it into a dedicated module—the digitized domain model. We then integrate it into your existing systems and plan your distributed architecture around it. The domain model is evolvable and the centralization of business logic helps adapt to change." },
      ],
      [
        { title: "Hexagonal Architecture", body: "This pattern creates a healthy separation between interfaces, application level concerns, and core business logic. The resulting modularity greatly facilitates the integration into your existing system and any possible future replatforms, improving your resilience to technology changes." },
        { title: "CQRS", body: "As the name suggests, Command Query Responsibility Segregation delineates the concerns of reading and writing. By focusing on each side independently, we can scale your ability to deliver both at a system level and an organization level." },
        { title: "Event Sourcing", body: "The holy grail of event-driven architecture, this unintuitive pattern has a lot of leverage over the more traditional, database-centric approaches. Xolvio’s secret weapon, event sourcing will provide you with superior business intelligence, scalability, and resilience." },
      ],
      [
        { title: "Serverless Architecture", body: "With the right design, serverless functions contribute to effortless software scaling. When coupled with a hexagonal architecture and a distributed, event-driven system, serverless will make capacity planning and infrastructure scaling a breeze." },
        { title: "Infrastructure as Code", body: "IaC removes manual processes from IT infrastructure management. It improves your ability to recover from disaster events, as well as gives you the ability to automatically create environments for different uses, such as performance testing or QA." },
        { title: "GraphQL Federation", body: "GraphQL allows frontend and backend teams to work independently. Further, a federated graph provides a unified composition layer for the entire company. As a result, everyone can focus on their part to deliver features faster, as well as build new value streams quickly, thus allowing to improve customer experience." },
      ],
    ]
  },
  whatYouGet: {
    subheading: "WHAT YOU GET",
    title: "Deliberate Scaling benefits:",
    benefits: [
      'Total control over increasing complexity',
      'Faster delivery of new features',
      'Improved integration between teams',
      'Higher team autonomy',
      'Greater system reliability & resilience',
      'Advanced BI, audit & traceability',
    ]
  }
}

export const getBenefitItem = (idx) => ({
  title: pageData.whatYouGet.benefits[idx],
  icon: <IconWrapper><StarIcon /></IconWrapper>,
  subheading: "",
  content: "",
  url: "",
  buttonText: ""
})
